import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
  InputAdornment,
  IconButton,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, useParams } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Controls from "../../Component/controls/Controls";
import Cookies from "universal-cookie";
import CardMedia from "@mui/material/CardMedia";
import { useForm, Form } from "../../Utils/useForm";
import Notification from "../../Utils/Notification";
import { ForgotPasswordupdate } from "../../state/action/authAction";
import Logo from "../../assets/contactus.png";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import { useSnackbar } from "notistack";
const initialValues = {
  newPassword: "",
  confirmPassword: ""
};

function ForgotPassword() {
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const { token } = useParams();

  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();
  //new password
  const handleClickShowNewPassword = () => setShowNewPassword((show) => !show);

  const handleMouseDownNewPassword = (event) => {
    event.preventDefault();
  };

  //Confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("newPassword" in fieldValues) {
      if (fieldValues.newPassword !== "" && fieldValues.newPassword !== undefined) {
        if (fieldValues.newPassword.length < 8) {
          temp.newPassword = "Password must be 8 characters";
        } else if (!/[0-9]/.test(fieldValues.newPassword)) {
          temp.newPassword = "Password must contain at least 1 number";
        } else if (!/[a-z]/.test(fieldValues.newPassword)) {
          temp.newPassword = "Password must contain at least 1 lowercase letter";
        } else if (!/[A-Z]/.test(fieldValues.newPassword)) {
          temp.newPassword = "Password must contain at least 1 uppercase letter";
        } else {
          temp.newPassword = "";
        }
      } else {
        temp.newPassword = "This field is required.";
      }
    }

    if ("confirmPassword" in fieldValues) {
      if (fieldValues.confirmPassword !== "" && fieldValues.confirmPassword !== undefined) {
        if (fieldValues.confirmPassword !== values.newPassword) {
          temp.confirmPassword = "Passwords do not match";
        } else {
          temp.confirmPassword = "";
        }
      } else {
        temp.confirmPassword = "This field is required.";
      }
    }

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };



  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const body = {
        password: values.newPassword,
        token: token
      };
      const res = await ForgotPasswordupdate(body);
      if (res && res.status === true) {
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
        Navigate("/login");
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
      console.log(res)
    }
  };


  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1e293b",
          height: { xs: "100%", sm: "100vh", md: "100vh" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "900px",
            margin: "0 auto",
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            item
            columns={{ xs: 4, sm: 12, md: 12 }}
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid item xs={6} md={6}>
              <Box
                sx={{
                  padding: { xs: "20px", md: "40px" },
                }}
              >
                <CardMedia
                  sx={{ marginBottom: "20px", width: "50%" }}
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                />
                <Typography
                  sx={{ fontSize: "35px", fontWeight: "bold", color: "#000" }}
                >
                  Reset Password
                </Typography>

                <Form onSubmit={handleSubmit}>
                  <FormControl
                    sx={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                    >
                      New Password
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "12px 14px !important",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          paddingTop: "10px!important",
                          borderColor: "rgba(107, 114, 128, .5)",

                          borderRadius: "7px!important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "blue",
                        },
                        paddingTop: "0px",
                        color: "#000",
                      }}
                      id="outlined-adornment-password"
                      name="newPassword"
                      value={values.newPassword}
                      onChange={handleInputChange}
                      type={showNewPassword ? "text" : "password"}
                      inputProps={{ maxLength: 15 }}
                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: "#000" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowNewPassword}
                            onMouseDown={handleMouseDownNewPassword}
                            edge="end"
                          >
                            {showNewPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {Boolean(errors.newPassword) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.newPassword}
                      </p>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <FormControl
                    sx={{ marginTop: "10px", width: "100%" }}
                    variant="outlined"
                  >
                    <InputLabel
                      htmlFor="outlined-adornment-password"
                      sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                    >
                      Confirm Password
                    </InputLabel>
                    <OutlinedInput
                      sx={{
                        "& .MuiInputBase-input": {
                          padding: "12px 14px !important",
                        },

                        "& .MuiOutlinedInput-notchedOutline": {
                          paddingTop: "10px!important",
                          borderColor: "rgba(107, 114, 128, .5)",

                          borderRadius: "7px!important",
                        },
                        "&:hover .MuiOutlinedInput-notchedOutline": {
                          borderColor: "black",
                        },
                        "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                          borderColor: "blue",
                        },
                        paddingTop: "0px",
                        color: "#000",
                      }}
                      id="outlined-adornment-password"
                      name="confirmPassword"
                      value={values.confirmPassword}
                      onChange={handleInputChange}
                      type={showConfirmPassword ? "text" : "password"}
                      inputProps={{ maxLength: 15 }}

                      endAdornment={
                        <InputAdornment position="end">
                          <IconButton
                            sx={{ color: "#000" }}
                            aria-label="toggle password visibility"
                            onClick={handleClickShowConfirmPassword}
                            onMouseDown={handleMouseDownConfirmPassword}
                            edge="end"
                          >
                            {showConfirmPassword ? (
                              <VisibilityOff />
                            ) : (
                              <Visibility />
                            )}
                          </IconButton>
                        </InputAdornment>
                      }
                    />
                    {Boolean(errors.confirmPassword) ? (
                      <p
                        style={{
                          color: "rgb(255 0 0)",
                          marginTop: "4px",
                          marginBottom: "4px",
                          fontSize: "14px",
                        }}
                      >
                        {errors.confirmPassword}
                      </p>
                    ) : (
                      ""
                    )}
                  </FormControl>
                  <LoadingButton
                    sx={{
                      ":hover": { bgcolor: "rgb(79, 70, 229)" },
                      marginTop: "20px",
                      textTransform: "none",
                      fontSize: "16px",
                      color: "#fff",
                      width: "100%",
                      backgroundColor: "rgb(79, 70, 229)",
                      borderRadius: "15px",
                      padding: "10px 40px",
                      "&.Mui-disabled": {
                        backgroundColor: "rgb(79, 70, 229)",
                        color: "#fff",
                      },
                    }}

                    type="submit"
                  >
                    Sign In
                  </LoadingButton>
                </Form>
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <Box className="login_img">
                <CardMedia
                  sx={{
                    maxWidth: "420px",
                    marginLeft: "auto",
                    padding: "10px",
                  }}
                  component="img"
                  image="../../assets/images/login-img.jpg"
                  alt="Paella dish"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
