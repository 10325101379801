import React, { useEffect, useState } from "react";
import {
  InputBase,
  MenuItem,
  Modal,
  Pagination,
  Select,
  IconButton,
} from "@mui/material";
import { useProSidebar } from "react-pro-sidebar";
import { Close } from "@mui/icons-material";
import FilterAltIcon from "@mui/icons-material/FilterAlt";
import { useTheme } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { tokens } from "../../theme";
import Typography from "@mui/material/Typography";
import { Box, Divider, Grid } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Tooltip, Drawer, FormLabel } from "@mui/material";
import { useForm } from "../../Utils/useForm";
import { AiOutlineCloseCircle } from "react-icons/ai";
import Button from "@mui/material/Button";
import Controls from "../../Component/controls/Controls";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell, { tableCellClasses } from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { styled } from "@mui/material/styles";
import BackdropComponent from "../../Component/BackDrop/BackDrop";
import ArrowUpwardIcon from "@mui/icons-material/ArrowUpward";
import ArrowDownwardIcon from "@mui/icons-material/ArrowDownward";
import moment from "moment";
import { useSelector } from "react-redux";
import { getAIMLInquiryDetails, getAIMLInquiryList } from "../../state/action/AIMLInquiryAction";
const initialValues = {
  fullname: "",
};


const StyledFormLabel = styled(FormLabel)(() => ({
  display: "block",
  fontWeight: "bold",
  color: "black",
  marginTop: "20px",
  marginBottom: "5px",
}));



const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {

    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

function createData(State, Status, Action) {
  return { State, Status, Action };
}
export default function BasicTabs() {
  const [openBackdrop, setOpenBreakdrop] = useState(false);
  const [NeedHelpList, setCompnay] = useState([]);
  const { collapsed, broken } = useProSidebar();
  const [pageLimit, setPageLimit] = useState(10);
  const [totalRecords, setTotalRecords] = useState(3);
  const [totalPages, setTotalPages] = useState(1);
  const [page, setPage] = useState(1);
  const [search, setSearch] = useState("");
  const [fieldName, setFieldName] = useState("");
  const [sortFlag, setSortFlag] = useState(false);
  const [refresh, setRefresh] = useState(false);
  const [flagName, setflagName] = useState(false);

  const [ContactusDetails, setContactusDetails] = useState("");
  const [openComanyDetailsModel, setOpenComanyDetailsModel] = useState(false);

  const { user } = useSelector((state) => state?.auth);



  const RecordPerPage = [
    { id: "10", title: "10" },
    { id: "20", title: "20" },
    { id: "30", title: "30" },
    { id: "40", title: "40" },
    { id: "50", title: "50" },
  ];

  const handleChangePage = (e, value) => {
    if (fieldName == "fullname") {
      NeedhelpList(value, fieldName, flagName, "");
    } else {
      NeedhelpList(value, "", "", "");
    }

    setPage(value);
  };

  const handleChangeRow = (event) => {
    if (fieldName == "fullname") {
      NeedhelpList(page, fieldName, flagName, event, "");
    } else {
      NeedhelpList(page, "", "", event);
    }
  };

  //ComanyDetails
  const handleCloseDetailsModel = () => setOpenComanyDetailsModel(false);
  const handleComanyDetailsModel = () => setOpenComanyDetailsModel(true);

  const [filterData, setFilterData] = useState(true);
  const [filter, setFilter] = useState(false);


  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues);

  useEffect(() => {
    NeedhelpList(page, "", "", "");
  }, [refresh, filterData, search, ""]);

  const NeedhelpList = async (page, fullname = "", flag, limit, organization) => {
    setOpenBreakdrop(true);
    let string = "";
    let i = 0;
    for (let [key, value] of Object.entries(values)) {
      string += `&${key}=${value}`;
      i++;
    }
    let recordLimit = limit ? limit : pageLimit;

    let queryString = `?page=${page}&search=${search}${string}&perPageSize=${recordLimit}&orderBy=${fullname}&sortFlag=${flag}&domain=${organization || ""}`;

    const res = await getAIMLInquiryList(queryString);
    if (res.status === true) {
      setCompnay(res.data);
      setTotalRecords(res.total);
      setPageLimit(recordLimit);
      setTotalPages(Math.ceil(res.total / recordLimit));
      setSortFlag(!sortFlag);
      setOpenBreakdrop(false);
    } else {
      setCompnay([]);
      setOpenBreakdrop(false);
      setTotalRecords(0);
      setTotalPages(0);
    }
    setFilter(false);
  };

  // details

  const getcompanyid = async (id) => {
    const res = await getAIMLInquiryDetails(id);
    if (res && res.status) {
      setContactusDetails(res?.data);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setFilterData(!filterData);
  };

  const openFilterDrawer = () => {
    setFilter(true);
  };
  const closeFilterDrawer = () => {
    setFilter(false);
    resetForm();
    setFilterData(!filterData);
  };
  const closeFilterDrawer1 = () => {
    setFilter(false);
  };

  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  const dataShorting = (fullname) => {
    if (fullname === "fullname") {
      setflagName(!flagName);
      NeedhelpList(page, fullname, !flagName, "", user?.organization);
    } else {
      setflagName(false);
      setSortFlag(!sortFlag);
    }

    setFieldName(fullname);
  };

  return (
    <>
      <Box>
        <BackdropComponent open={openBackdrop} />

        <Box sx={{ px: 3, backgroundColor: "rgb(241, 245, 249)" }}>
          <Grid
            container
            style={{
              paddingTop: "40px",
              paddingBottom: "40px",
              alignItems: "center",
            }}
          >
            <Grid item sm={4} xs={12}>
              <Typography variant="h2" sx={{ fontWeight: "bold" }}>
                AI-ML Inquiry
              </Typography>
            </Grid>
            <Grid item sm={8} xs={12}>
              <Box
                sx={{
                  display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
                  marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" },
                }}
                justifyContent="flex-end"
              >
                <Box
                  border={1}
                  borderColor="rgba(0, 0, 0, .1)"
                  alignItems="center"
                  display="flex"
                  backgroundColor="#ffffffa3"
                  p={0.4}
                  borderRadius={10}
                >
                  <SearchIcon
                    style={{
                      color: colors.grey[300],
                    }}
                    sx={{ ml: 1, fontSize: "24px" }}
                  />
                  <InputBase
                    name="search"
                    sx={{ ml: 1, flex: 1, color: "#000", fontSize: "16px" }}
                    placeholder="Search"
                    onChange={(e) => {
                      setTimeout(() => {
                        setSearch(e.target.value);
                      }, 800);
                    }}
                  />
                </Box>
                <Box
                  display="flex"
                  sx={{ marginTop: { xs: "20px", sm: "0", md: "0", lg: "0" } }}
                >
                  <Button
                    variant="outlined"
                    startIcon={<FilterAltIcon />}
                    onClick={openFilterDrawer}
                    sx={{
                      marginLeft: "15px",
                      borderRadius: "50px",
                      paddingTop: "5px",
                      paddingBottom: "5px",
                      paddingLeft: "30px",
                      paddingRight: "30px",
                      color: "#fff",
                      ":hover": { bgcolor: "rgb(30, 41, 59)" },
                      background: "rgb(30, 41, 59)",
                      fontSize: "16px",
                      fontWeight: "500",
                      textTransform: "none",
                    }}
                  >
                    Filter
                  </Button>
                </Box>
              </Box>
            </Grid>
          </Grid>
        </Box>
        <Box
          sx={{
            backgroundColor: "rgb(241, 245, 249)",
            width: "98%",
            margin: "auto",
          }}
        >
          <Box
            sx={{
              border: 1,
              borderColor: "#eaeaea",
            }}
          >
            <TableContainer component={Paper}>
              <Table aria-label="customized table">
                <TableHead>
                  <TableRow>
                    <StyledTableCell>Id</StyledTableCell>
                    <StyledTableCell onClick={() => dataShorting("fullname")}>
                      Name
                      {flagName ? (
                        <ArrowUpwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      ) : (
                        <ArrowDownwardIcon
                          sx={{
                            color: "rgba(107, 114, 128, .5)",
                            fontSize: "17px",
                            marginLeft: "3px",
                          }}
                        />
                      )}
                    </StyledTableCell>
                    <StyledTableCell>Email</StyledTableCell>
                    <StyledTableCell>Mobile</StyledTableCell>
                    <StyledTableCell>Organization</StyledTableCell>
                    <StyledTableCell>Date</StyledTableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {NeedHelpList?.map((row) => {
                    return (
                      <StyledTableRow

                        sx={{ cursor: "pointer" }}
                        onClick={(e) => {
                          handleComanyDetailsModel(e);
                          getcompanyid(row?.id);
                        }}
                      >
                        <StyledTableCell component="th" scope="row">
                          {row?.id}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.fullname || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.email || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.phone || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {row?.organization || "-"}
                        </StyledTableCell>
                        <StyledTableCell component="th" scope="row">
                          {moment(row.createdAt).format("MM-DD-YYYY LT")}
                        </StyledTableCell>
                      </StyledTableRow>
                    );
                  })}
                </TableBody>
              </Table>
              {NeedHelpList && NeedHelpList.length === 0 ? (
                <div
                  className="noDataFound"
                  style={{
                    display: "flex",
                  }}
                >
                  {" "}
                  No Data Found
                </div>
              ) : null}
            </TableContainer>
          </Box>
        </Box>

        <Box
          sx={{
            display: { xs: "block", sm: "flex", md: "flex", lg: "flex" },
            justifyContent: "space-between",
            alignItems: "center",
            marginLeft: { xs: "10px", sm: "20px", md: "20px", lg: "20px" },
            marginRight: { xs: "10px", sm: "15px", md: "15px", lg: "15px" },
            mt: 2,
            mb: 2,
          }}
        >
          <Box
            sx={{
              fontSize: "14px",
              marginBottom: { xs: "10px", sm: "0", md: "0", lg: "0" },
              gap: 2,
              width: { sm: "100%", md: "50%" },
            }}
            display="flex"
            alignItems="center"
          >
            <Box>
              {" "}
              Showing{" "}
              {NeedHelpList.length
                ? NeedHelpList.length
                : page * pageLimit} of {totalRecords} Results
            </Box>
            <Box display="flex" sx={{ gap: 1 }} alignItems="center">
              <Typography>Records per page</Typography>
              <Select
                className="recordLimit"
                sx={{
                  color: "#000",
                  height: "40px",
                  fontSize: "15px",
                  borderRadius: "5px",
                  border: 1,
                  paddingLeft: "10px",
                  borderColor: "#000",
                  ".MuiSvgIcon-root ": {
                    fill: "black !important",
                  },
                  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                    border: 0,
                  },
                }}
                id="limites"
                name="limites"
                value={pageLimit}
                onChange={(e) => {
                  handleChangeRow(e.target.value);
                }}
              >
                {RecordPerPage.map((item) => {
                  return (
                    <MenuItem key={item.id} value={item.id}>
                      {item.name || item.title}
                    </MenuItem>
                  );
                })}
              </Select>
            </Box>
          </Box>
          <Pagination
            count={totalPages}
            page={page}
            onChange={handleChangePage}
            variant="outlined"
            shape="rounded"
            sx={{
              "& .MuiButtonBase-root ": {
                color: "#000 !important",
              },
              "& .Mui-selected ": {
                background: "#4b4b4e70 !important",
              },
              "& .Mui-disabled ": {
                background: "#6c696966 !important",
                color: "#000000 !important",
                opacity: 1,
              },
            }}
          />
        </Box>

        <Modal
          keepMounted
          open={openComanyDetailsModel}
          onClose={handleCloseDetailsModel}
          aria-labelledby="keep-mounted-modal-title"
          aria-describedby="keep-mounted-modal-description"
        >
          <Box
            className="modelBox"
            sx={{
              position: "absolute",
              top: "50%",
              left: {
                xs: "50%",
                sm: broken ? "50%" : collapsed ? "50%" : "65%",
                md: collapsed ? "50%" : "60%",
                lg: collapsed ? "50%" : "55%",
              },
              transform: "translate(-50%, -50%)",
              width: { xs: "320px", sm: "400px" },
              border: "0",
              outline: "0",
              bgcolor: "#fff",
              borderRadius: "10px",
              p: 3,
              height: "auto",
              overflow: "scroll",
              marginBottom: '30px'
            }}
          >
            <Typography sx={{ fontSize: "18px", fontWeight: "bold", display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
              {" "}
              ContactUs Details
              <Tooltip title="Close">
                <IconButton
                  onClick={() => {
                    handleCloseDetailsModel()
                  }}
                >
                  <Close sx={{ color: "black" }} />
                </IconButton>
              </Tooltip>
            </Typography>

            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid container gap={2} width={{ xs: '250px', sm: '400px' }} paddingY={1}>
                  <Grid item xs={12}>
                    <Box sx={{ background: '#f1f1f1', borderRadius: '15px', padding: '20px' }}>
                      <Grid container paddingBottom={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'} fontWeight={600}>Name :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'}>{ContactusDetails.fullname || "-"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container paddingBottom={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'} fontWeight={600}>Email :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'}>{ContactusDetails.email || "-"}</Typography>
                        </Grid>
                      </Grid>

                      <Grid container paddingBottom={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'} fontWeight={600}>Contact :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'}>{ContactusDetails.countryCode} {ContactusDetails.phone || "-"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container paddingBottom={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'} fontWeight={600}>Organization :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'}>{ContactusDetails.organization || "-"}</Typography>
                        </Grid>
                      </Grid>
                      <Grid container paddingBottom={1}>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'} fontWeight={600}>Message :</Typography>
                        </Grid>
                        <Grid item xs={12} sm={6}>
                          <Typography fontSize={'13px'}>{ContactusDetails.message || "-"}</Typography>
                        </Grid>
                      </Grid>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Box>
        </Modal>

        <Drawer open={filter} onClose={closeFilterDrawer} anchor="right">
          <form
            onSubmit={handleSubmit}
            style={{
              height: "100%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "space-between",
              backgroundColor: "#fff",
            }}
          >
            <Box
              sx={{
                Width: { xs: "100%", sm: "300px", md: "300px", lg: "300px" },
                padding: "10px 20px",
              }}
            >
              <Stack
                flexDirection="row"
                alignItems="center"
                justifyContent="space-between"
              >
                <Typography
                  sx={{ fontWeight: "bold", color: "#000", fontSize: "30px" }}
                >
                  Filter
                </Typography>

                <AiOutlineCloseCircle
                  className="close-button"
                  onClick={closeFilterDrawer1}
                />
              </Stack>

              <Box
                sx={{
                  marginTop: "5px",
                }}
              >
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
              </Box>

              <Box>
                <StyledFormLabel htmlFor="hsnCode">Name</StyledFormLabel>
                <Controls.Input
                  className="input"
                  fullWidth
                  id="fullname"
                  name="fullname"
                  value={values.fullname}
                  onChange={handleInputChange}
                />
                <StyledFormLabel htmlFor="hsnCode">Email</StyledFormLabel>
                <Controls.Input
                  className="input"
                  fullWidth
                  id="email"
                  name="email"
                  value={values.email}
                  onChange={handleInputChange}
                />

              </Box>
            </Box>
            <Stack sx={{ margin: "5px 20px 20px 20px" }} flexDirection="row">
              <button className="cancel-filter" onClick={closeFilterDrawer}>
                Reset
              </button>
              <button
                className="order-filter-button"
                type="submit"
                style={{
                  cursor: "pointer",
                }}
              >
                Filter
              </button>
            </Stack>
          </form>
        </Drawer>

      </Box>
    </>
  );
}
