import axiosRequest from "../axios/axios";

//get Banner List
export const getAIMLInquiryList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`contactusListAIML` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Company Details

export const getAIMLInquiryDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/contactDetailAIML/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
