import React, { useState, useEffect } from "react";
import {
  Box,
  Grid,
  Typography,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation } from "react-router-dom";
import LoadingButton from "@mui/lab/LoadingButton";
import Controls from "../../Component/controls/Controls";
import Cookies from "universal-cookie";
import CardMedia from "@mui/material/CardMedia";
import { useForm, Form } from "../../Utils/useForm";
import Notification from "../../Utils/Notification";
import { ForgotPasswords } from "../../state/action/authAction";
import Logo from "../../assets/contactus.png";
import { useSnackbar } from "notistack";
const initialValues = { email: ""};

function ForgotPassword() {
  const { enqueueSnackbar } = useSnackbar();
  const Navigate = useNavigate();
  const validate = (fieldValues = values) => {
    let temp = { ...errors };
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        if (!/$^|.+@.+..+/.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }
   
    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };

  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (validate()) {
      const body ={
        email :values.email
      } 
      const res = await ForgotPasswords(body);
      if (res && res.status === true) {
        enqueueSnackbar(res.message, {
          variant: "success",
          autoHideDuration: 2000,
        });
        Navigate("/login");
      } else {
        enqueueSnackbar(res.message, {
          variant: "error",
          autoHideDuration: 2000,
        });
      }
    }
  };

  
  return (
    <>
      <Box
        sx={{
          backgroundColor: "#1e293b",
          height: { xs: "100%", sm: "100vh", md: "100vh" },
          display: "flex",
          alignItems: "center",
        }}
      >
        <Box
          sx={{
            width: "900px",
            margin: "0 auto",
            backgroundColor: "#fff",
            borderRadius: "20px",
          }}
        >
          <Grid
            container
            item
            columns={{ xs: 4, sm: 12, md: 12 }}
            spacing={1}
            sx={{
              alignItems: "center",
            }}
          >
            <Grid item xs={6} md={6}>
              <Box
                sx={{
                  padding: { xs: "20px", md: "40px" },
                }}
              >
               <CardMedia
                  sx={{ marginBottom: "20px", width: "50%" }}
                  component="img"
                  image={Logo}
                  alt="Paella dish"
                />
                <Typography
                  sx={{ fontSize: "35px", fontWeight: "bold", color: "#000" }}
                >
                  Forgot Password
                </Typography>

                <Form onSubmit={handleSubmit}>
                  <Box sx={{ marginBottom: "10px" }}>
                    <Controls.Input
                      text="Add New"
                      variant="outlined"
                      color="primary"
                      label="Email"
                      name="email"
                      value={values.email}
                      onChange={handleInputChange}
                    />
                  </Box>
                  {Boolean(errors.email) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.email}
                    </p>
                  ) : (
                    ""
                  )}
                  <LoadingButton
                    sx={{
                      ":hover": { bgcolor: "rgb(79, 70, 229)" },
                      marginTop: "20px",
                      textTransform: "none",
                      fontSize: "16px",
                      color: "#fff",
                      width: "100%",
                      backgroundColor: "rgb(79, 70, 229)",
                      borderRadius: "15px",
                      padding: "10px 40px",
                      "&.Mui-disabled": {
                        backgroundColor: "rgb(79, 70, 229)",
                        color: "#fff",
                      },
                    }}
                 
                    type="submit"
                  >
                    Sign In
                  </LoadingButton>
                </Form>
              </Box>
            </Grid>

            <Grid item xs={6} md={6}>
              <Box className="login_img">
                <CardMedia
                  sx={{
                    maxWidth: "420px",
                    marginLeft: "auto",
                    padding: "10px",
                  }}
                  component="img"
                  image="../../assets/images/login-img.jpg"
                  alt="Paella dish"
                />
              </Box>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </>
  );
}

export default ForgotPassword;
