// docs https://github.com/azouaoui-med/react-pro-sidebar
import { useState } from "react";
import { Menu, Sidebar, MenuItem } from "react-pro-sidebar";
import { useProSidebar } from "react-pro-sidebar";
import { useSidebarContext } from "./sidebarContext";
import { tokens } from "../../../theme";
import { useTheme, Box, Typography, IconButton } from "@mui/material";
import MenuOutlinedIcon from "@mui/icons-material/MenuOutlined";
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch';
import BusinessIcon from '@mui/icons-material/Business';
import { useNavigate } from "react-router-dom";
import { Link } from "react-router-dom";
import { Tooltip } from "@mui/material";
import { ContactEmergency } from "@mui/icons-material";
import { useDispatch, useSelector } from "react-redux";
const Item = ({ title, to, icon, selected, setSelected }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const Navigate = useNavigate();

  return (
    <MenuItem
      active={selected === title}
      style={{ color: colors.grey[100] }}
      onClick={() => {
        setSelected(title);
        Navigate(to);
      }}
      icon={icon}
      routerLink={<Link to={to} />}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};
const MyProSidebar = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [selected, setSelected] = useState("Dashboard");
  const { sidebarRTL, setSidebarRTL, sidebarImage } = useSidebarContext();
  const { collapseSidebar, toggleSidebar, collapsed, broken } = useProSidebar();
  const Role = useSelector((state) => state?.auth?.user);
  const Roles = Role && Role.role ? Role.role : 1;

  return (
    <Box
      sx={{
        position: "sticky",
        display: "flex",
        height: "100vh",
        top: 0,
        bottom: 0,
        zIndex: 10000,
        "& .sidebar": {
          border: "none",
        },
        "& .menu-icon": {
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item": {
          padding: "5px 10px 5px 10px !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-anchor": {
          color: "inherit !important",
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item:hover": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
        "& .menu-item.active": {
          color: `#fff!important`,
          backgroundColor: "rgba(255, 255, 255, 0.1) !important",
        },
      }}
    >
      <Sidebar
        id="sidebar"
        breakPoint="md"
        rtl={sidebarRTL}
        backgroundColor={colors.primary[600]}
        image={sidebarImage}
      >
        <Menu iconshape="square">
          <MenuItem
            icon={
              collapsed ? (
                <MenuOutlinedIcon onClick={() => collapseSidebar()} />
              ) : ""
            }
            style={{
              margin: "10px 0 20px 0",
              color: colors.grey[100],
            }}
          >
            {!collapsed && (
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
                ml="15px"
              >
                <Typography variant="h3" sx={{ color: "#fff" }}>
                  {/* <img src={Logo} alt="" style={{ width: "81px" }} /> */}
                  Contact Us
                </Typography>
                <IconButton
                  onClick={
                    broken ? () => toggleSidebar() : () => collapseSidebar()
                  }
                >
                  <MenuOutlinedIcon />
                </IconButton>
              </Box>
            )}
          </MenuItem>

          <Box
            display={collapsed ? "none" : "block"}
            padding={collapsed ? undefined : "0% 3%"}
          >
            {Roles === 1 ? (
              <Item
                title="Company"
                to="/company"
                icon={<BusinessIcon />}
                selected={selected}
                setSelected={setSelected}
              />
            ) : null}

            <Item
              title="ContactUs"
              to="/contactus"
              icon={<ContactEmergency />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Nettyfy ContactUs"
              to="/nettyfycontactus"
              icon={<ContactEmergency />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="AIML Inquiry"
              to="/AIMLInquiry"
              icon={<ContactEmergency />}
              selected={selected}
              setSelected={setSelected}
            />

            <Item
              title="Popup"
              to="/popup"
              icon={<ContactEmergency />}
              selected={selected}
              setSelected={setSelected}
            />
            <Item
              title="PopupData"
              to="/popupdata"
              icon={<ContactEmergency />}
              selected={selected}
              setSelected={setSelected}
            />
          </Box>
          <Box
            display={collapsed ? "block" : "none"}
            padding={collapsed ? undefined : "0% 3%"}
          >

            {Roles === 1 ? (
              <Tooltip
                style={{ zIndex: "20000 !important" }}
                placement="right"
                title="Company"
                arrow
              >
                <div>
                  <Item
                    to="/company"
                    icon={<BusinessIcon />}
                    selected={selected}
                    setSelected={setSelected}
                  />
                </div>
              </Tooltip>
            ) : null}
            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="ContactUs"
              arrow
            >
              <div>
                <Item
                  to="/contactus"
                  icon={<ContactEmergency />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="Popup"
              arrow
            >
              <div>
                <Item
                  to="/popup"
                  icon={<ContactEmergency />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>

            <Tooltip
              style={{ zIndex: "20000 !important" }}
              placement="right"
              title="PopupData"
              arrow
            >
              <div>
                <Item
                  to="/popupdata"
                  icon={<ContactEmergency />}
                  selected={selected}
                  setSelected={setSelected}
                />
              </div>
            </Tooltip>
          </Box>
        </Menu>
      </Sidebar>
    </Box>
  );
};

export default MyProSidebar;
