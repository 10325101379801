import { Box } from "@mui/material";
import React from "react";
import ContactUsList from "./ContactUsList";

const ContactUs = () => {
  return (
    <Box>
      <ContactUsList />
    </Box>
  );
};

export default ContactUs;