import axiosRequest from "../axios/axios";

//get Banner List
export const getCompanyList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`companyList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Banner

export const AddCompany = async (payload) => {
  try {
    const response = await axiosRequest.post(`addCompany`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Banner Delete
export const DeleteCompany = async (id) => {
  try {
    const response = await axiosRequest.delete(`compnayDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner Status Update

export const StatusUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`compnayStatusChange/${id}`, payload);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner update

export const updateCompany = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`companyUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


// Company Details

export const getCompanyDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/companyDetail/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};


export const CompanyChangePassword = async (payload) => {
  try {
    const response = await axiosRequest.put(`changePassword`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};