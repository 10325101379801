import axiosRequest from "../axios/axios";

//get Banner List
export const getPopupList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`popupList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

//add Banner

export const AddPopup = async (payload) => {
  try {
    const response = await axiosRequest.post(`addPopup`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};

//Banner Delete
export const PopupDelete = async (id) => {
  try {
    const response = await axiosRequest.delete(`popupDelete/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner Status Update

export const StatusUpdate = async (id) => {
  try {
    const response = await axiosRequest.put(`popupStatusChange/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};

//Banner update

export const popupUpdate = async (id, payload) => {
  try {
    const response = await axiosRequest.put(`popupUpdate/${id}`, payload);
    return response.data;
  } catch (error) {
    return error.response.data;
  }
};


// Company Details

export const getPopupDetail = async (id) => {
  try {
    const response = await axiosRequest.get(`/popupDetail/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
