import React from "react";
import Dashboard from "../../pages/Dashboard/Dashboard";
import { Routes, Route } from "react-router-dom";
import Company from "../../pages/Company/company";
import Popup from "../../pages/popup/popup";
import Popupdata from "../../pages/Popupdata/Popupdata";
import ContactUs from "../../pages/ContactUs/Contactus";
import ContactUsAIML from "../../pages/AIMLContactus/ContactusAIML";
import NetttyfyContactUs from "../../pages/NettyfyContactus/ContactusNettyfy";

function Main() {
  return (
    <div>
      <Routes>
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/company" element={<Company />} />
        <Route path="/contactus" element={<ContactUs />} />
        <Route path="/nettyfycontactus" element={<NetttyfyContactUs />} />
        <Route path="/AIMLInquiry" element={<ContactUsAIML />} />
        <Route path="/popup" element={<Popup />} />
        <Route path="/popupdata" element={<Popupdata />} />
      </Routes>
    </div>
  );
}

export default Main;
