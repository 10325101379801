import { Box } from "@mui/material";
import React from "react";
import PopupList from "./popupList";

const Popup = () => {
    return (
        <Box>
            <PopupList />
        </Box>
    );
};

export default Popup;