import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
} from "@mui/material";
import { Grid, Typography, IconButton } from "@mui/material";
import { Tooltip } from "@mui/material";
import { Close } from "@mui/icons-material";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import { Upload, Button } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { Select } from 'antd';
import { AddPopup, popupUpdate } from "../../state/action/PopupAction"
import { useLocation } from 'react-router-dom';
import { useSnackbar } from "notistack";

const options = [];
for (let i = 10; i < 36; i++) {
    options.push({
        value: i.toString(36) + i,
        label: i.toString(36) + i,
    });
}

const initialValues = {
    title: "",
    name: "",
    email: "",
    phoneNo: "",
    message: "",
    image: "",
    backgroundImage: "",
    description: "",
    smallTitle: "",
    dropdown: [],
    pageSource: "",
    button: []
};

const AddNeedHelp = (props) => {

    const { open, handleClose, needHelpData, refreshScreen, isEdit } =
        props;

    const { state } = useLocation();
    const { enqueueSnackbar } = useSnackbar();
    const [nameValue, setNameValue] = useState(0);
    const [emailValue, setEmailValue] = useState(0);
    const [phoneNoValue, setPhoneValue] = useState(0);
    const [logoImage, setLogoImage] = useState({});
    const [bannerImage, setBannerImage] = useState({});

    const [button, setButton] = useState(1);
    const [buttons, setButtons] = useState(Array(button).fill(''));
    const handleChange = (e) => {
        const selectedValue = parseInt(e.target.value, 10);
        setButton(selectedValue);

        if (selectedValue <= buttons.length) {
            setButtons(buttons.slice(0, selectedValue));
        } else {
            setButtons(Array(selectedValue).fill(''));
        }
    };

    const handleNameCheckboxChange = (event) => {
        setNameValue(event.target.checked ? 1 : 0);
    };
    const handleEmailCheckboxChange = (event) => {
        setEmailValue(event.target.checked ? 1 : 0);
    };
    const handlePhoneCheckboxChange = (event) => {
        setPhoneValue(event.target.checked ? 1 : 0);
    };

    const validate = (fieldValues = values) => {
        let temp = { ...errors };

        if ("title" in fieldValues)
            temp.title = fieldValues.title ? "" : "This field is required.";

        if (isCheckboxChecked === true) {
            if ("smallTitle" in fieldValues)
                temp.smallTitle = fieldValues.smallTitle ? "" : "This field is required.";
        }
        setErrors({
            ...temp,
        });

        if (fieldValues === values)
            return Object.values(temp).every((x) => x === "");
    };

    const handleImageItem = (info) => {
        if (info.file.status === 'done') {
            setBannerImage(info.file.originFileObj);
        } else if (info.file.status === 'error') {
            setBannerImage("");
        }
    };

    const handleImageItems = (info) => {
        if (info.file.status === 'done') {
            setLogoImage(info.file.originFileObj);
        } else if (info.file.status === 'error') {
            setLogoImage("");
        }
    };


    const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
        useForm(initialValues, true, validate);


    const close = () => {
        handleClose();
        resetForm();
        setNameValue(0);
        setEmailValue(0)
        setPhoneValue(0)
        setButton('')
        setButtons([])
        setIsCheckboxChecked(false)
        setBannerImage('');
        setLogoImage('')
    };

    const [confirmDialog, setConfirmDialog] = useState(false);

    const openConfirmDialog = () => {
        setConfirmDialog(true);
    };

    const [isCheckboxChecked, setIsCheckboxChecked] = useState(false);

    const handleCheckboxChange = (event) => {
        setIsCheckboxChecked(event.target.checked);
    };

    useEffect(() => {
        setIsCheckboxChecked(!!needHelpData.smallTitle);
    }, [isEdit, needHelpData]);

    const handleChangeDropdown = (value) => {
        setValues({
            ...values,
            dropdown: value,
        });
    };

    const handleButtons = (e, index) => {
        if (e.target.value !== '') {
            const newButtonName = [...buttons];
            newButtonName[index] = e.target.value;
            setButtons(newButtonName);
        } else {
            // Show a notification for blank value
            enqueueSnackbar("Button name cannot be blank", { variant: "error" });
        }
    };
    const buttonSelect = [
        { name: '1', title: '1', id: 1 },
        { name: '2', title: '2', id: 2 },
        { name: '3', title: '3', id: 3 },
    ]
    useEffect(() => {
        if (isEdit) {
            setValues({
                ...values,
                title: needHelpData.title,
                name: needHelpData.name,
                email: needHelpData.email,
                phoneNo: needHelpData.phoneNo,
                message: needHelpData.message,
                description: needHelpData.description,
                smallTitle: needHelpData.smallTitle,
                dropdown: JSON.parse(needHelpData.dropdown),
                button: JSON.parse(needHelpData.button),
                pageSource: needHelpData.pageSource,
                image: needHelpData.image,
                backgroundImage: needHelpData.backgroundImage,
            });
            setNameValue(needHelpData.name === 1 ? 1 : 0);
            setEmailValue(needHelpData.email === 1 ? 1 : 0);
            setPhoneValue(needHelpData.phoneNo === 1 ? 1 : 0);
            setButtons(JSON.parse(needHelpData.button));
            setIsCheckboxChecked(!!needHelpData.smallTitle);
        } else {
            resetForm();
        }
    }, [isEdit, needHelpData]);


    useEffect(() => {
        if (isEdit) {
            setLogoImage(needHelpData?.image);
        } else {
            setLogoImage("");
        }
    }, [isEdit, needHelpData]);

    useEffect(() => {
        if (isEdit) {
            setBannerImage(needHelpData?.backgroundImage);
        } else {
            setBannerImage("");
        }
    }, [isEdit, needHelpData]);

    const onSubmit = async (e) => {
        e.preventDefault();
        if (validate()) {
            const formData = new FormData();
            console.log("values.button", buttons);
            formData.append("title", values?.title);
            formData.append("name", nameValue);
            formData.append("email", emailValue);
            formData.append("phoneNo", phoneNoValue);
            formData.append("message", values?.message);
            formData.append("description", values?.description);
            formData.append("smallTitle", values?.smallTitle);
            formData.append("dropdown", JSON.stringify(values?.dropdown));
            formData.append("button", JSON.stringify(buttons));
            formData.append("pageSource", values.pageSource);
            if (logoImage && logoImage.name) {
                formData.append("image", logoImage);
            }
            if (bannerImage && bannerImage.name) {
                formData.append("backgroundImage", bannerImage);
            }
            let res
            if (isEdit) {
                res = await popupUpdate(needHelpData?.id, formData);
            } else {
                res = await AddPopup(formData);
            }
            if (res && res.status === true) {
                close();
                refreshScreen();
                enqueueSnackbar(res.message, {
                    variant: "success",
                    autoHideDuration: 2000,
                });
            } else {
                enqueueSnackbar(res.message, {
                    variant: "error",
                    autoHideDuration: 2000,
                });
            }
        }
    };

    return (
        <div>
            {/* add new user modal */}
            <Dialog
                open={open}
                onClose={openConfirmDialog}
                fullWidth={true}
                maxWidth="lg"
                sx={{ width: "50%", marginLeft: "450px", color: "black" }}
            >
                <Form
                    onSubmit={onSubmit}
                >
                    <DialogTitle
                        sx={{
                            fontFamily: "Work Sans",
                            fontStyle: "normal",
                            fontWeight: 600,
                            fontSize: "20px",
                            lineHeight: "30px",
                            display: "flex",
                            justifyContent: "space-between",
                            color: "black",
                        }}
                    >
                        {isEdit ? "Edit Popup " : "Add Popup"}
                        <Tooltip title="Close">
                            <IconButton
                                onClick={() => {
                                    close();
                                }}
                            >
                                <Close sx={{ color: "black" }} />
                            </IconButton>
                        </Tooltip>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container spacing={1}>
                            <Grid item md={12} sm={12} xs={12}>
                                <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="Title"
                                    name="title"
                                    value={values.title}
                                    onChange={handleInputChange}
                                    error={errors.title}
                                    helperText={errors.title}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                            <Grid item xs={12}>
                                <Controls.Checkbox
                                    label='Add Name'
                                    checked={nameValue === 1}
                                    onChange={handleNameCheckboxChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Checkbox
                                    label='Add Email'
                                    checked={emailValue === 1}
                                    onChange={handleEmailCheckboxChange}
                                />
                            </Grid>

                            <Grid item xs={12}>
                                <Controls.Checkbox
                                    label='Add PhoneNo.'
                                    checked={phoneNoValue === 1}
                                    onChange={handlePhoneCheckboxChange}
                                />
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                                <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="Message"
                                    name="message"
                                    value={values.message}
                                    onChange={handleInputChange}
                                    error={errors.message}
                                    helperText={errors.message}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <Controls.Input
                                    text="Add New"
                                    variant="outlined"
                                    color="primary"
                                    label="Text Description"
                                    name="description"
                                    value={values.description}
                                    onChange={handleInputChange}
                                    error={errors.description}
                                    helperText={errors.description}
                                    inputProps={{ maxLength: 100 }}
                                />
                            </Grid>
                            <Grid item md={12} sm={12} xs={12}>
                                <Typography sx={{ color: '#000', fontWeight: '600', fontSize: '14px' }}>Select Buttons</Typography>
                                <Controls.Select
                                    value={button}
                                    options={buttonSelect}
                                    onChange={handleChange}
                                />
                            </Grid>

                            <Grid item md={12} sm={12} xs={12}>
                                <Stack direction={'row'} gap={1}>
                                    {buttons.map((item, index) => (
                                        <Controls.Input
                                            key={index}
                                            text={`Add New`}
                                            variant="outlined"
                                            color="primary"
                                            label={`Button ${index + 1}`}
                                            name={`button${index + 1}`}
                                            value={item}
                                            onChange={(e) => handleButtons(e, index)}
                                        />
                                    ))}
                                </Stack>
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ color: '#000', fontWeight: '600', fontSize: '14px', marginBottom: '7px' }}>Upload Banner Image</Typography>
                                <Upload
                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                    listType="picture"
                                    maxCount={1}
                                    onChange={handleImageItem}
                                >
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                                {/* Display banner image if available */}
                                {bannerImage && (
                                    <img src={bannerImage} alt="Banner Image" style={{ width: '100%', marginTop: '10px' }} />
                                )}
                            </Grid>
                            <Grid item xs={6}>
                                <Typography sx={{ color: '#000', fontWeight: '600', fontSize: '14px', marginBottom: '7px' }}>Upload Logo Image</Typography>
                                <Upload
                                    action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
                                    listType="picture"
                                    onChange={handleImageItems}
                                    maxCount={1}
                                >
                                    <Button icon={<UploadOutlined />}>Upload Image</Button>
                                </Upload>
                                {/* Display logo image if available */}
                                {logoImage && (
                                    <img src={logoImage} alt="Logo Image" style={{ width: '100%', marginTop: '10px' }} />
                                )}
                            </Grid>
                            <Select
                                mode="tags"
                                style={{ width: '100%' }}
                                placeholder="Dropdowns"
                                onChange={(value) => handleChangeDropdown(value)}
                                value={values.dropdown}
                                options={options}
                            />

                            <Grid item xs={12}>
                                <Controls.Checkbox
                                    label='Add Small Title'
                                    checked={isCheckboxChecked}
                                    onChange={handleCheckboxChange}
                                />
                            </Grid>
                            {isCheckboxChecked && (
                                <Grid item md={12} sm={12} xs={12}>
                                    <Controls.Input
                                        text="Add New"
                                        variant="outlined"
                                        color="primary"
                                        label="Small Title"
                                        name="smallTitle"
                                        value={values.smallTitle}
                                        onChange={handleInputChange}
                                        error={errors.smallTitle}
                                        helperText={errors.smallTitle}
                                        inputProps={{ maxLength: 100 }}
                                    />
                                </Grid>
                            )}
                        </Grid>
                    </DialogContent>

                    <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
                        <Grid container style={{ marginTop: "2rem" }}>
                            <Grid item xs={9} className="text-end">
                                <Controls.Button
                                    style={{
                                        backgroundColor: "#fff",
                                        marginRight: "-40px",
                                        color: "#000",
                                    }}
                                    text="Cancel"
                                    onClick={() => {
                                        close();
                                    }}
                                />
                            </Grid>
                            <Grid item xs={3} className="text-end">
                                <Controls.Button
                                    style={{ marginRight: "7px" }}
                                    text="Submit"
                                    onClick={onSubmit}
                                    type="submit"
                                />
                            </Grid>
                        </Grid>
                    </DialogActions>
                </Form>
            </Dialog>
        </div>
    );
};

export default AddNeedHelp;
