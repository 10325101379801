import React, { useState, useEffect } from "react";
import {
  Divider,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  tableCellClasses,
} from "@mui/material";
import { Space } from "antd";
import { RiDeleteBinLine } from "react-icons/ri";
import AddIcon from "@mui/icons-material/Add";
import { Grid, Box, Typography, IconButton } from "@mui/material";
import camera from "../../assets/camera.svg";
import { Tooltip } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Close } from "@mui/icons-material";
import { useSnackbar } from "notistack";
import { Form, useForm } from "../../Utils/useForm";
import Controls from "../../Component/controls/Controls";
import FormControl from "@mui/material/FormControl";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import OutlinedInput from "@mui/material/OutlinedInput";
import InputLabel from "@mui/material/InputLabel";
import InputAdornment from "@mui/material/InputAdornment";
import {
  AddCompany,
  updateCompany,
} from "../../state/action/companyAction";

const initialValues = {
  companyName: "",
  email: "",
  password: "",
  confirmPassword: "",
  domain: "",
  subject: "",
  companyLogo: "",
  contact: "",
  ownerName: "",
};



const StyledImageUploadWrapper = styled(Box)(() => ({
  marginTop: "16px",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  height: "206px",
  borderRadius: "10px",
  textAlign: "center",
}));

const StyledInput = styled("input")({
  display: "none",
});

const StyledIconWrapper = styled(Box)(() => ({
  margin: "20px auto",
  border: "1px solid rgba(15, 15, 15, 0.15)",
  width: "80px",
  height: "80px",
  borderRadius: "10px",
  backgroundColor: "rgba(15, 15, 15, 0.05)",

  img: {
    marginTop: "30px",
  },
}));

const StyledRemoveButton = styled(Box)({
  color: "red",
  display: "inline",
  "&:hover": {
    cursor: "pointer",
  },
});

const StyledTableCell = styled(TableCell)(({ theme }) => ({
  [`&.${tableCellClasses.head}`]: {

    color: "#000",
    fontSize: 13,
    border: 0,
    fontWeight: 600,
    paddingTop: "15px",
    paddingBottom: "15px",
    paddingLeft: "25px",
  },
  [`&.${tableCellClasses.body}`]: {
    fontSize: 13,
    color: "rgb(17, 24, 39)",
    border: 0,
    paddingLeft: "25px",

    paddingTop: "10px",
    paddingBottom: "10px",
  },
}));

const StyledTableRow = styled(TableRow)(({ theme }) => ({
  "&": {
    backgroundColor: "#fff",
    ":hover": { backgroundColor: "rgb(248 248 248)!important" },

    "&:nth-of-type(odd)": {
      backgroundColor: "rgb(250 250 250)!important",
    },
  },
  // hide last border
  "&:last-child td, &:last-child th": {
    border: 0,
  },
}));

const AddNeedHelp = (props) => {
  const { open, handleClose, needHelpData, refreshScreen, isEdit } =
    props;

  const { enqueueSnackbar } = useSnackbar();
  const [vehicleImage, setVehicleImage] = useState({});
  const maxSize = 3072;
  const [rowsData, setRowsData] = useState([]);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const addccemailRows = () => {
    const rowsInput = {
      ccemail: "",
    };

    setRowsData([...rowsData, rowsInput]);
  };


  const deleteaddccemailRows = (index) => {
    const rows = [...rowsData];
    rows.splice(index, 1);
    setRowsData(rows);
  };

  const handleChanges = (index, e) => {
    const { name, value } = e.target;
    const rowsInput = [...rowsData];
    rowsInput[index][name] = value;
    setRowsData(rowsInput);
  };

  const data = rowsData?.map((row) => row);

  const validateImageSize = (vehicleIcons) => {
    if (vehicleIcons) {
      const fsize = vehicleIcons.size / 1024;
      if (fsize > maxSize) {
        return false;
      } else {
        return true;
      }
    }
  };

  async function handleImageItem(e) {
    if (validateImageSize(e.target.files[0])) {
      const selected = e.target.files[0];
      const ALLOWED_TYPES = ["image/png", "image/jpg", "image/jpeg"];
      if (selected && ALLOWED_TYPES.includes(selected.type)) {
        const base64String = await getBase64(selected);
        setImage(base64String);
        setVehicleImage(selected);
        setValues({ ...values, vehicleIcon: selected });
      } else {
      }
    } else {
      enqueueSnackbar("Please Upload logo should be less then 3 MB only", {
        variant: "error",
        autoHideDuration: 2000,
      });
    }
  }

  const getBase64 = (file) => {
    return new Promise((resolve) => {
      let baseURL = "";
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        baseURL = reader.result;
        resolve(baseURL);
      };
    });
  };
  const removeImage = () => {
    resetImage();
    setValues((previousValues) => {
      var newValues = { ...previousValues };
      delete newValues["bannerImage"];
      return newValues;
    });
    setVehicleImage({});
  };
  const [image, setImage] = useState("");

  const resetImage = () => {
    setImage("");
  };

  //Confirm password
  const handleClickShowConfirmPassword = () =>
    setShowConfirmPassword((show) => !show);

  const handleMouseDownConfirmPassword = (event) => {
    event.preventDefault();
  };

  const validate = (fieldValues = values) => {
    let temp = { ...errors };

    if ("companyName" in fieldValues)
      temp.companyName = fieldValues.companyName ? "" : "This field is required.";
    if ("ownerName" in fieldValues)
      temp.ownerName = fieldValues.ownerName ? "" : "This field is required.";
    if ("email" in fieldValues) {
      if (fieldValues.email !== "" && fieldValues.email !== undefined) {
        // Regular expression for email validation with domain
        const emailRegex = /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/;

        if (!emailRegex.test(fieldValues.email)) {
          temp.email = "Invalid Email";
        } else {
          temp.email = "";
        }
      } else {
        temp.email = "This field is required.";
      }
    }

    if (!isEdit) {
      if ("password" in fieldValues) {
        if (fieldValues.password !== "" && fieldValues.password !== undefined) {
          if (fieldValues.password.length < 8) {
            temp.password = "Password  must be 8 charecter";
          } else {
            if (!/[0-9]/.test(fieldValues.password)) {
              temp.password = "Password  must be 1 Number";
            } else {
              if (!/[a-z]/.test(fieldValues.password)) {
                temp.password = "Password  must be 1 Lowercase letter";
              } else {
                if (!/[A-Z]/.test(fieldValues.password)) {
                  temp.password = "Password  must be 1 Uppercase letter";
                } else {
                  temp.password = "";
                }
              }
            }
          }
        } else {
          temp.password = "This field is required.";
        }
      }
    }
    if (!isEdit) {
      if ("confirmPassword" in fieldValues) {
        if (fieldValues.confirmPassword !== "" && fieldValues.confirmPassword !== undefined) {
          if (fieldValues.confirmPassword !== values.password) {
            temp.confirmPassword = "Passwords do not match";
          } else {
            temp.confirmPassword = "";
          }
        } else {
          temp.confirmPassword = "This field is required.";
        }
      }
    }
    if ("contact" in fieldValues) {
      let regex2 = /^[0-9\b]+(\.[0-9]{1,2})?$/;
      if (regex2.test(fieldValues.contact)) {
        temp.contact = "";
      } else {
        temp.contact = "Only number allowed";
      }
    }

    if ("domain" in fieldValues) {
      const domainRegex = /^(https?:\/\/)?([a-zA-Z0-9.-]+\.[a-zA-Z]{2,})(:[0-9]{1,5})?(\/[^\s]*)?$/;
      if (!domainRegex.test(fieldValues.domain)) {
        temp.domain = "Please enter a valid domain.";
      } else {
        temp.domain = ""; // Domain is valid
      }
    }

    if ("subject" in fieldValues)
      temp.subject = fieldValues.subject ? "" : "This field is required.";

    setErrors({
      ...temp,
    });

    if (fieldValues === values)
      return Object.values(temp).every((x) => x === "");
  };
  const [showPassword, setShowPassword] = React.useState(false);
  const handleClickShowPassword = () => setShowPassword((show) => !show);
  const handleMouseDownPassword = (event) => {
    event.preventDefault();
  };
  const { values, setValues, errors, setErrors, handleInputChange, resetForm } =
    useForm(initialValues, true, validate);



  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {

      const formData = new FormData();
      formData.append("companyName", values.companyName);
      formData.append("ownerName", values.ownerName);
      formData.append("email", values.email);
      formData.append("password", values.password);
      formData.append("domain", values.domain);
      formData.append("subject", values.subject);
      formData.append("contact", values.contact);
      if (vehicleImage && vehicleImage.name) {
        formData.append("companyLogo", vehicleImage);
      }
      formData.append("companyemail", JSON.stringify(data));

      if (isEdit) {
        const res = await updateCompany(needHelpData.id, formData);
        if (res && res.status === true) {
          close();
          refreshScreen();
          resetForm();
          setImage("");
          setRowsData([])

          enqueueSnackbar(res.message, {
            variant: "success",
            autoHideDuration: 2000,
          });
        } else {
          enqueueSnackbar(res.message, {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }

      if (!isEdit) {
        if (vehicleImage && vehicleImage.name) {
          const res = await AddCompany(formData);
          if (res && res.status === true) {
            close();
            refreshScreen();
            resetForm();
            enqueueSnackbar(res.message, {
              variant: "success",
              autoHideDuration: 2000,
            });
          } else {
            enqueueSnackbar(res.message, {
              variant: "error",
              autoHideDuration: 2000,
            });
          }
        } else {
          enqueueSnackbar("Please Selecte logo", {
            variant: "error",
            autoHideDuration: 2000,
          });
        }
      }
    }
  };
  const close = () => {
    handleClose();
  };

  const [confirmDialog, setConfirmDialog] = useState(false);

  const openConfirmDialog = () => {
    setConfirmDialog(true);
  };

  useEffect(() => {
    if (isEdit) {
      setValues({
        ...values,
        companyName: needHelpData.companyName,
        ownerName: needHelpData.ownerName,
        email: needHelpData.email,
        password: needHelpData.password,
        domain: needHelpData.domain,
        subject: needHelpData.subject,
        contact: needHelpData.contact,
        companyLogo: needHelpData.companyLogo,
        companyemail: needHelpData.companyemail,
      });
      setRowsData(needHelpData?.companyemail || []);
    } else {
      resetForm();
    }
  }, [isEdit, needHelpData]);

  useEffect(() => {
    if (isEdit) {
      setImage(needHelpData?.companyLogo);
      setVehicleImage(needHelpData?.companyLogo);
    } else {
      setImage("");
    }
  }, [isEdit, needHelpData]);

  return (
    <div>
      {/* add new user modal */}
      <Dialog
        open={open}
        onClose={openConfirmDialog}
        fullWidth={true}
        maxWidth="lg"
        sx={{ width: "50%", marginLeft: "450px", color: "black" }}
      >
        <Form onSubmit={handleSubmit}>
          <DialogTitle
            sx={{
              fontFamily: "Work Sans",
              fontStyle: "normal",
              fontWeight: 600,
              fontSize: "20px",
              lineHeight: "30px",
              display: "flex",
              justifyContent: "space-between",
              color: "black",
            }}
          >
            {isEdit ? "Edit Company " : "Add Company"}
            <Tooltip title="Close">
              <IconButton
                onClick={() => {
                  resetForm();
                  close();
                  setImage("");
                }}
              >
                <Close sx={{ color: "black" }} />
              </IconButton>
            </Tooltip>
          </DialogTitle>

          <DialogContent>
            <Grid container spacing={2}>
              <Grid item md={12} sm={12} xs={12} container spacing={1}>
                <Grid item md={12} sm={12} xs={12}>
                  <StyledImageUploadWrapper>
                    <label htmlFor="image-upload">
                      <StyledInput
                        accept="image/*"
                        id="image-upload"
                        type="file"
                        onChange={handleImageItem}
                        onClick={(event) => {
                          event.currentTarget.value = null;
                        }}
                      />
                      <StyledIconWrapper
                        sx={
                          image == ""
                            ? {}
                            : {
                              backgroundImage: `url(${image})`,
                              backgroundRepeat: "no-repeat",
                              backgroundSize: "cover",
                            }
                        }
                      >
                        {image === "" && <img src={camera} alt="Camera" />}
                      </StyledIconWrapper>
                    </label>
                    <Typography
                      sx={{
                        fontWeight: "bold",
                        fontSize: "14px",
                        color: "black",
                      }}
                    >
                      {isEdit ? "Edit Profile Logo" : "Add Profile Logo"}
                    </Typography>
                    <Typography
                      sx={{
                        color: "rgba(15, 15, 15, 0.5)",
                        mx: 2,
                        fontSize: "12px",
                      }}
                    >
                      Allowed *.jpeg, *.jpg, *.png, max size of 3 MB
                    </Typography>

                    {image != "" && (
                      <StyledRemoveButton
                        onClick={(e) => {
                          e.preventDefault();
                          removeImage();
                        }}
                      >
                        Remove
                      </StyledRemoveButton>
                    )}
                  </StyledImageUploadWrapper>
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Company Name"
                    name="companyName"
                    value={values.companyName}
                    onChange={handleInputChange}
                    error={errors.companyName}
                    helperText={errors.companyName}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Owner Name"
                    name="ownerName"
                    value={values.ownerName}
                    onChange={handleInputChange}
                    error={errors.ownerName}
                    helperText={errors.ownerName}
                    inputProps={{ maxLength: 100 }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Email"
                    name="email"
                    value={values.email}
                    onChange={handleInputChange}
                    error={errors.email}
                    helperText={errors.email}
                    inputProps={{ maxLength: 200 }}
                    readOnly
                  />
                </Grid>
                {!isEdit ? (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      sx={{ marginTop: "10px", width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                      >
                        Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "12px 14px !important",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",

                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="outlined-adornment-password"
                        name="password"
                        value={values.password}
                        onChange={handleInputChange}
                        type={showPassword ? "text" : "password"}
                        inputProps={{ maxLength: 15 }}
                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: "#000" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowPassword}
                              onMouseDown={handleMouseDownPassword}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(errors.password) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.password}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                ) : ''}

                {!isEdit ? (
                  <Grid item md={12} xs={12}>
                    <FormControl
                      sx={{ marginTop: "10px", width: "100%" }}
                      variant="outlined"
                    >
                      <InputLabel
                        htmlFor="outlined-adornment-password"
                        sx={{ color: "#000", bgcolor: "#fff", lineHeight: "1" }}
                      >
                        Confirm Password
                      </InputLabel>
                      <OutlinedInput
                        sx={{
                          "& .MuiInputBase-input": {
                            padding: "12px 14px !important",
                          },

                          "& .MuiOutlinedInput-notchedOutline": {
                            paddingTop: "10px!important",
                            borderColor: "rgba(107, 114, 128, .5)",

                            borderRadius: "7px!important",
                          },
                          "&:hover .MuiOutlinedInput-notchedOutline": {
                            borderColor: "black",
                          },
                          "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
                            borderColor: "blue",
                          },
                          paddingTop: "0px",
                          color: "#000",
                        }}
                        id="outlined-adornment-password"
                        name="confirmPassword"
                        value={values.confirmPassword}
                        onChange={handleInputChange}
                        type={showConfirmPassword ? "text" : "password"}
                        inputProps={{ maxLength: 15 }}

                        endAdornment={
                          <InputAdornment position="end">
                            <IconButton
                              sx={{ color: "#000" }}
                              aria-label="toggle password visibility"
                              onClick={handleClickShowConfirmPassword}
                              onMouseDown={handleMouseDownConfirmPassword}
                              edge="end"
                            >
                              {showConfirmPassword ? (
                                <VisibilityOff />
                              ) : (
                                <Visibility />
                              )}
                            </IconButton>
                          </InputAdornment>
                        }
                      />
                      {Boolean(errors.confirmPassword) ? (
                        <p
                          style={{
                            color: "rgb(255 0 0)",
                            marginTop: "4px",
                            marginBottom: "4px",
                            fontSize: "14px",
                          }}
                        >
                          {errors.confirmPassword}
                        </p>
                      ) : (
                        ""
                      )}
                    </FormControl>
                  </Grid>
                ) : ''}
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Contact"
                    name="contact"
                    value={values.contact}
                    onChange={handleInputChange}
                    error={errors.contact}
                    helperText={errors.contact}
                    inputProps={{ maxLength: 15 }}
                  />
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Domain"
                    name="domain"
                    value={values.domain}
                    onChange={handleInputChange}
                    inputProps={{ maxLength: 150 }}
                    readOnly
                  />
                  {Boolean(errors.domain) ? (
                    <p
                      style={{
                        color: "rgb(255 0 0)",
                        marginTop: "4px",
                        marginBottom: "4px",
                        fontSize: "14px",
                      }}
                    >
                      {errors.domain}
                    </p>
                  ) : (
                    ""
                  )}
                </Grid>
                <Grid item md={12} xs={12}>
                  <Controls.Input
                    text="Add New"
                    variant="outlined"
                    color="primary"
                    label="Subject"
                    name="subject"
                    value={values.subject}
                    onChange={handleInputChange}
                    error={errors.subject}
                    helperText={errors.subject}
                    inputProps={{ maxLength: 150 }}
                  />
                </Grid>
                <Divider sx={{ bgcolor: "rgba(107, 114, 128, .4)" }} />
                <Grid
                  item md={12} xs={12}
                  container
                >
                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Typography
                      sx={{
                        fontWeight: "400",
                        marginLeft: "28px",
                        fontSize: "20px",
                      }}
                    >
                      Description
                    </Typography>
                  </Grid>

                  <Grid item sx={{ m: 1 }} md={12} xs={12}>
                    <Box
                      sx={{
                        backgroundColor: "rgb(241, 245, 249)",
                        width: "98%",
                        margin: "auto",
                      }}
                    >
                      <Box
                        sx={{
                          border: 1,
                          borderColor: "#eaeaea",
                        }}
                      >
                        <Table className="table">
                          <TableHead>
                            <TableRow>
                              <StyledTableCell>CC Email</StyledTableCell>
                              <StyledTableCell sx={{ width: "10px" }}>
                                <Button
                                  variant="contained"
                                  size="large"
                                  style={{
                                    backgroundColor: "blue",
                                    borderRadius: "10px",
                                    width: "0%",
                                  }}
                                  startIcon={<AddIcon sx={{ marginLeft: "9px" }} />}
                                  fullWidth
                                  onClick={addccemailRows}
                                />
                              </StyledTableCell>
                            </TableRow>
                          </TableHead>
                          <TableBody>
                            {rowsData?.map((data, index) => {
                              const { ccemail } =
                                data;
                              return (
                                <StyledTableRow
                                  key={index}
                                  sx={{ cursor: "pointer" }}
                                >
                                  <StyledTableCell component="th" scope="row">
                                    <Controls.Input
                                      text="Add New"
                                      variant="outlined"
                                      color="primary"
                                      label="CC Email"
                                      name="ccemail"
                                      value={ccemail}
                                      onChange={(e) => {
                                        handleChanges(index, e);
                                        handleInputChange(e);
                                      }}
                                    />
                                  </StyledTableCell>
                                  <StyledTableCell
                                    onClick={(e) => {
                                      e.stopPropagation();
                                    }}
                                  >
                                    <Space size="middle">
                                      <Tooltip placement="bottom" title="Delete ">
                                        <button
                                          className="action-button delete-btn"
                                          onClick={() => deleteaddccemailRows(index)}
                                          style={{ marginLeft: "15px" }}
                                        >
                                          <RiDeleteBinLine />
                                        </button>
                                      </Tooltip>
                                    </Space>
                                  </StyledTableCell>
                                </StyledTableRow>
                              );
                            })}
                          </TableBody>
                        </Table>
                      </Box>
                    </Box>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </DialogContent>

          <DialogActions sx={{ margin: "15px", marginTop: "-22px" }}>
            <Grid container style={{ marginTop: "2rem" }}>
              <Grid item xs={9} className="text-end">
                <Controls.Button
                  style={{
                    backgroundColor: "#fff",
                    marginRight: "-40px",
                    color: "#000",
                  }}
                  text="Cancel"
                  onClick={() => {
                    close();
                    resetForm();
                    setImage("");
                  }}
                />
              </Grid>
              <Grid item xs={3} className="text-end">
                <Controls.Button
                  style={{ marginRight: "7px" }}
                  text="Submit"
                  onClick={handleSubmit}
                  type="submit"
                />
              </Grid>
            </Grid>
          </DialogActions>
        </Form>
      </Dialog>
    </div>
  );
};

export default AddNeedHelp;
