import React from 'react'
import { Box,Typography } from "@mui/material";

function Dashboard() {
  return (
    <Box display="flex" justifyContent="space-between" p={2}>
    <Typography variant="h2"
    sx={{ fontWeight: "bold"}}>
    Dashboard
</Typography>
    </Box>
  )
}

export default Dashboard