import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useSelector } from "react-redux";

function PrivateRoute({ children }) {
  const token = useSelector((state) => state.auth.token);
  // const userdata = useSelector((state) => state.auth.user);


// logout 

  // if(userdata.role === 1 && token === null){
    return token ? children : <Navigate to="/login" />;
  // }else{
  //   return token ? children : <Navigate to="/companylogin" />;
  // }

}

export default PrivateRoute;
