import React from "react";

//create common checkbox component
import Checkbox from "@mui/material/Checkbox";
import FormControlLabel from "@mui/material/FormControlLabel";
import { styled } from "@mui/material/styles";


const WhiteBorderTextField = styled(Checkbox)`
  & .MuiSvgIcon-root {
    fill:black;
  }
  & .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border-color: black;
  }
  & label.Mui-focused {
    color: black;
  }
`;

export default function Checkboxs(props) {
    const { name, label, value, onChange, checked, disabled } = props;
    return (
        <FormControlLabel
            control={
                <WhiteBorderTextField
                    checked={checked}
                    onChange={onChange}
                    name={name}
                    color="primary"
                    disabled={disabled}
                />
            }
            sx={{color:'#000 !important'}}
            label={label}
        />
    );
}
