import React from 'react';
import './App.css';
import { ColorModeContext, useMode } from './theme';
import { ThemeProvider } from '@mui/material';
import { useEffect } from "react";
import Layout from "./Component/Layout/Layout";
import Login from "./pages/Login/Login";
import ForgotPassword from "./pages/Login/ForgotPassword";
import ForgotPasswords from "./pages/Login/ForgotPasswords";
import { Routes, Route, useLocation } from "react-router-dom";
import PrivateRoute from "./Routes/PrivetRoute";
import { loadUserApi } from "./state/action/authAction";
import { useDispatch, useSelector } from "react-redux";
const App = () => {
  const [theme, colorMode] = useMode();
  const dispatch = useDispatch();
  const location = useLocation();
  const pathname = location.pathname.substr(1);

  useEffect(() => {
    dispatch(loadUserApi());
  }, [])

  useEffect(() => {
    document.title = `Contact Us || ${pathname}`;
  }, [pathname]);

  let routeElement = <Layout />;

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
      <Routes>
          <Route exact path="/login" element={<Login />} />
          <Route exact path="/companylogin" element={<Login />} />
          <Route exact path="/forgotpassword" element={<ForgotPassword />} />
          <Route exact path="/resetPassword/:token" element={<ForgotPasswords />} />
          <Route path="*" element={<PrivateRoute path="/login">
            {routeElement}
          </PrivateRoute>} />
        </Routes>
          {/* <Layout /> */}
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default App;
