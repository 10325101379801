import axiosRequest from "../axios/axios";

//get Banner List
export const getNettyfyContactUsList = async (queryString) => {
  try {
    const response = await axiosRequest.get(`nettyfyContactusList` + queryString);
    return response.data;
  } catch (error) {
    return error;
  }
};

// Company Details

export const getNettyfyContactUsDetails = async (id) => {
  try {
    const response = await axiosRequest.get(`/nettyfyContactDetail/${id}`);
    return response.data;
  } catch (error) {
    return error;
  }
};
